
import Vue from "vue";
import SiteFooterMenu from "~/components/layout/footer/footer-middle/FooterMenu.vue";
import { SiteFooterMenuItem } from "~/components/layout/footer/footer-middle/FooterMenu";
import { fetchBrDocument } from "~/services/bloomreach.service";
import { BloomreachFolderPaths } from "~/services/api/bloomreach.api";

interface BloomreachMenuDocument {
  buyingFromUs: { item: SiteFooterMenuItem[] };
  companyInformation: { item: SiteFooterMenuItem[] };
  tradeAccount: { item: SiteFooterMenuItem[] };
  usefulGuides: { item: SiteFooterMenuItem[] };
}

export default Vue.extend({
  components: {
    SiteFooterMenu,
  },
  data: () => ({
    activeMenu: "",
    buyingFromUsItems: [] as SiteFooterMenuItem[],
    companyInfoItems: [] as SiteFooterMenuItem[],
    tradeAccountItems: [] as SiteFooterMenuItem[],
    usefulGuidesItems: [] as SiteFooterMenuItem[],
  }),
  async fetch() {
    await this.fetchFooterMenu();
  },
  computed: {
    footerMenus(): {
      [key: string]: { id: string; title: string; items: SiteFooterMenuItem[] };
    } {
      return {
        buyingFromUs: {
          id: "buying-from-us",
          title: this.$tc("footer.buyingFromUs"),
          items: this.buyingFromUsItems,
        },
        companyInformation: {
          id: "company-information",
          title: this.$tc("footer.companyInformation"),
          items: this.companyInfoItems,
        },
        tradeAccount: {
          id: "trade-account",
          title: this.$tc("footer.tradeAccount"),
          items: this.tradeAccountItems,
        },
        usefulGuides: {
          id: "useful-guides",
          title: this.$tc("footer.usefulGuides"),
          items: this.usefulGuidesItems,
        },
      };
    },
  },
  methods: {
    async fetchFooterMenu(): Promise<void> {
      try {
        const { brxmContentEndpoint: brContentURL } = this.$config;

        await fetchBrDocument(
          BloomreachFolderPaths.Footer,
          "footer",
          brContentURL
        ).then((menu: BloomreachMenuDocument) => {
          this.buyingFromUsItems = menu?.buyingFromUs.item ?? [];
          this.companyInfoItems = menu?.companyInformation.item ?? [];
          this.tradeAccountItems = menu?.tradeAccount.item ?? [];
          this.usefulGuidesItems = menu?.usefulGuides.item ?? [];
        });
      } catch (error) {
        console.error("Error fetching footer menu:", error);
      }
    },

    isActive(id: string): boolean {
      return this.activeMenu === id;
    },
    setActiveMenu(id: string): void {
      if (this.isActive(id)) {
        this.activeMenu = "";
      } else {
        this.activeMenu = id;
      }
    },
  },
});
